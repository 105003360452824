<template>
  <div>
    <h3>Bem Vindo, {{ displayName }}</h3>
    <v-row>
      <v-col cols="12"></v-col>
      <v-col cols="12">
        <a
          href="https://www.instagram.com/irivofutebol"
          target="_blank"
        >
          <v-icon size="22">
            {{ icons.mdiInstagram }}
          </v-icon>
        </a>
        Instagram
      </v-col>
      <v-col cols="12">
        <a
          href="https://www.facebook.com/irivofutebolweb"
          target="_blank"
        >
          <v-icon size="22">
            {{ icons.mdiFacebook }}
          </v-icon>
        </a>
        Facebook
      </v-col>
      <v-col cols="12">
        <a
          href="https://www.youtube.com/@irivocanal1071"
          target="_blank"
        >
          <v-icon size="22">
            {{ icons.mdiYoutube }}
          </v-icon>
        </a>
        Youtube
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, inject } from '@vue/composition-api'
import {
  mdiInstagram,
  mdiFacebook,
  mdiYoutube,
} from '@mdi/js'

export default {
  setup() {
    const userService = inject('userservice')

    const displayName = ref('')

    userService.getCurrentUserDetails()
      .then(response => {
        displayName.value = response.displayName
      })

    return {
      displayName,
      icons: {
        mdiInstagram,
        mdiFacebook,
        mdiYoutube,
      },
    }
  },
}
</script>
